/* eslint-disable camelcase */
import {ComponentsContext} from '@oracle-cx-commerce/react-ui/contexts';
import Page from '@oracle-cx-commerce/react-components/page';
import Provider from '@oracle-cx-commerce/react-components/provider';
import React from 'react';

import {fetchSite} from '@oracle-cx-commerce/fetchers/site';
import {useSiteFetcher} from '@oracle-cx-commerce/fetchers/site/hooks';
import {GiftListProvider} from '../plugins/contexts/gift-list-provider';
import {ConfigDataLayerProvider} from '../plugins/contexts/config-data-layer-provider';
import {WLProductSelectionProvider} from '../plugins/components/wedding-list/contexts/jbq-wl-product-selection';
import {WLProductRouteProvider} from '../plugins/components/wedding-list/contexts/jbq-wl-product-route';
import {WLProfileProvider} from '../plugins/contexts/wl-profile-provider';

export const fetchers = [fetchSite];

const Root = ({components, store}) => {
  useSiteFetcher(store);

  return (
    <ComponentsContext.Provider value={components}>
      <Provider store={store}>
        <ConfigDataLayerProvider>
          <GiftListProvider>
            <WLProfileProvider>
              <WLProductSelectionProvider>
                <WLProductRouteProvider>
                  <Page />
                </WLProductRouteProvider>
              </WLProductSelectionProvider>
            </WLProfileProvider>
          </GiftListProvider>
        </ConfigDataLayerProvider>
      </Provider>
    </ComponentsContext.Provider>
  );
};
export default Root;
