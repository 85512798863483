import React, {useState, createContext, useContext, useEffect} from 'react';
import {PAGE_LINKS_WITH_PRODUCT_SELECTION} from '../constants';

export const WLProductSelection = createContext([]);

export const WLProductSelectionProvider = props => {
  const [productSelection, setProductSelection] = useState([]);
  const [productsToAdd, setProductsToAdd] = useState([]);
  const [productsToSelectAll, setProductsToSelectAll] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);

  const urlMustHaveSelectionContext = (getCurrentUrl, isWeddingList) => {
    if (!isWeddingList && PAGE_LINKS_WITH_PRODUCT_SELECTION.includes(getCurrentUrl)) {
      setProductSelection([]);
    }
  };

  useEffect(() => {
    const producstArrayKeys = Object.keys(productSelection);
    const activedProducts = [];
    producstArrayKeys.forEach(productId => {
      if (productSelection[productId].isActive) activedProducts.push(productSelection[productId]);
    });
    setProductsToAdd(activedProducts);
  }, [productSelection, setProductsToAdd]);

  return (
    <WLProductSelection.Provider
      value={{
        productSelection,
        setProductSelection,
        isAllSelected,
        setIsAllSelected,
        productsToAdd,
        urlMustHaveSelectionContext,
        productsToSelectAll,
        setProductsToSelectAll,
        setProductsToAdd
      }}
    >
      {props.children}
    </WLProductSelection.Provider>
  );
};

export const useWLProductSelectionContext = () => useContext(WLProductSelection);
