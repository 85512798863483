/* eslint-disable camelcase */
import {SHIPPING_GROUP_HARDGOOD} from '@oracle-cx-commerce/commerce-utils/constants';

export const loadShippingMethods = async (
  action,
  shippingAddress,
  shippingGroup,
  currentOrder = {priceInfo: {amount: 0}},
  type = SHIPPING_GROUP_HARDGOOD
) => {
  const methods = {
    status: '',
    shippingMethods: []
  };
  try {
    const {shippingGroupId} = shippingGroup;

    const resp = await action('updateCartShippingGroup', {
      shippingGroupId,
      type,
      shippingMethod: {value: null},
      shippingAddress
    });

    if (resp.status === 400) {
      methods.status = 'error';

      return methods;
    }

    const response = await action('listShippingMethodsForCart', {shippingGroupIds: shippingGroupId});
    const methodExternal = {shippingMethods: [], status: ''};
    if (response.status === 200 && response.ok) {
      methods.status = 'success';
      methods.shippingMethods = Object.values(response.delta.catalogRepository.shippingMethods);
      methods.shippingMethods.forEach(item => {
        if (item.shippingCalculator === 'external') {
          const {priceInfo} = currentOrder;
          let isFreeShipping = false;
          for (let i = 0; i < item.ranges.length; i++) {
            const range = item.ranges[i];
            if (range.high) {
              if (priceInfo.subTotal >= range.low && priceInfo.subTotal <= range.high) {
                isFreeShipping = true;
                break;
              }
            } else if (priceInfo.subTotal >= range.low) {
              isFreeShipping = true;
              break;
            }
          }

          if (isFreeShipping) {
            methodExternal.shippingMethods.push(item);
            methodExternal.status = 'success';
          }
        }
      });
    } else {
      methods.status = 'error';
    }

    return methodExternal.shippingMethods.length > 0 ? methodExternal : methods;
  } catch (error) {
    methods.status = 'error';

    return methods;
  }
};

export const treatShippingValue = (ranges, totalOrderPrice) => {
  let value = 0;
  for (let i = 0; i < ranges.length; i++) {
    const range = ranges[i];
    if (range.high) {
      if (totalOrderPrice >= range.low && totalOrderPrice <= range.high) {
        value = range.amount;
        break;
      }
    } else if (totalOrderPrice >= range.low) {
      value = range.amount;
      break;
    }
  }

  return value;
};

export const clearShippingMethod = async (action, shippingGroupId) => {
  const resp = await action('updateCartShippingGroup', {
    shippingGroupId,
    type: SHIPPING_GROUP_HARDGOOD,
    shippingMethod: {value: null},
    shippingAddress: {}
  });

  return resp;
};

export const updateCartShippingMethod = async (action, shippingMethodId, shippingGroupId) => {
  const response = await action('updateCartShippingGroup', {
    type: SHIPPING_GROUP_HARDGOOD,
    shippingMethod: {value: shippingMethodId},
    shippingGroupId
  });

  return response;
};
