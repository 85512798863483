import {useState, useEffect, useCallback, useContext} from 'react';
import {StoreContext} from '@oracle-cx-commerce/react-ui/contexts';

export const useSite = () => {
  const [site, setSite] = useState(null);

  const {action} = useContext(StoreContext);

  const loadSite = useCallback(async () => {
    const siteId = 'siteUS';

    const response = await action('getSite', {siteId});

    const siteData = response.delta.siteRepository.sites[siteId];

    setSite(siteData);
  }, [action]);

  useEffect(() => {
    loadSite();
  }, [loadSite]);

  return site;
};
