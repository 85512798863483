import {useState, useEffect} from 'react';

const useBaseUrl = () => {
  const [baseUrl, setBaseUrl] = useState('');

  useEffect(() => {
    // eslint-disable-next-line no-undef
    setBaseUrl(`${window.location.protocol}//${window.location.host}`);
  }, []);

  return baseUrl;
};

export default useBaseUrl;
