import React, {createContext, useContext, useState, useCallback} from 'react';
import {PAGE_PROFILE_LINK, PAGE_HOME_LINK} from '@oracle-cx-commerce/commerce-utils/constants/page-links';

const WLProfileContext = createContext({});

export const WLProfileProvider = ({children}) => {
  const [breadcrumb, setBreadcrumb] = useState([]);
  const defaultCrumbs = [
    {name: 'Home', link: PAGE_HOME_LINK},
    {name: 'Minha conta', link: PAGE_PROFILE_LINK}
  ];
  const handleBreadcrumb = useCallback(
    crumb => {
      setBreadcrumb([crumb]);
    },
    [setBreadcrumb]
  );

  return (
    <WLProfileContext.Provider
      value={{
        breadcrumb: [...defaultCrumbs, ...breadcrumb],
        handleBreadcrumb,
        setBreadcrumb
      }}
    >
      {children}
    </WLProfileContext.Provider>
  );
};

export function useWLProfile() {
  return useContext(WLProfileContext);
}
