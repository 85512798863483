/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable unicorn/prefer-query-selector */
/* eslint-disable no-undef */
import React, {useState, createContext, useContext, useEffect} from 'react';
import {install} from 'ga-gtag';
import {useSite} from '../hooks/use-site';

const ConfigDataLayerContext = createContext({});

export function ConfigDataLayerProvider({children}) {
  const [hasEmitDataLayer, setHasEmitDataLayer] = useState(false);
  const [pathname] = useState(window.location.pathname);
  const site = useSite();

  useEffect(() => {
    if (site !== null && hasEmitDataLayer === false) {
      // eslint-disable-next-line no-inner-declarations
      const idGTM = site?.siteSettings?.dataLayerProperties?.idGTM ?? '';

      install(idGTM, {
        // eslint-disable-next-line camelcase
        send_page_view: !!idGTM || false
      });

      setHasEmitDataLayer(true);
    }
  }, [hasEmitDataLayer, site]);

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    // eslint-disable-next-line no-inner-declarations
    function gtag() {
      // eslint-disable-next-line prefer-rest-params
      dataLayer.push(arguments);
    }
    gtag('js', new Date());

    gtag('config', 'UA-24297774-2');
  }, [pathname]);

  return <ConfigDataLayerContext.Provider value={{hasEmitDataLayer}}>{children}</ConfigDataLayerContext.Provider>;
}

export function useDataLayerProvider() {
  return useContext(ConfigDataLayerContext);
}
