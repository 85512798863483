/* eslint-disable no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable camelcase */
import React, {useState, createContext, useContext, useEffect, useCallback} from 'react';
import {useSelector} from '@oracle-cx-commerce/react-components/provider';
import {StoreContext} from '@oracle-cx-commerce/react-ui/contexts';
import axios from 'axios';
import {
  getCurrentOrder,
  isAuthenticated,
  getCurrentProfile,
  getCartEndpointStatus
} from '@oracle-cx-commerce/commerce-utils/selector';
import useBaseUrl from '../hooks/use-base-url';
import {updateCartShippingMethod} from '../utils/shipping-methods';
import {useSite} from '../hooks/use-site';
import {GIFT_LIST_DYNAMIC_PROPERTY} from '../constants/wedding-list';

const GiftListContext = createContext({});

export const GiftListProvider = ({children}) => {
  const {action} = useContext(StoreContext);
  const currentOrder = useSelector(getCurrentOrder);
  const currentProfile = useSelector(getCurrentProfile);
  const isUserLoggedIn = useSelector(isAuthenticated);
  const isGetCartInProgress = useSelector(getCartEndpointStatus);
  const [loggedOff, setLoggedOff] = useState(false);
  const [productAdded, setProductAdded] = useState(false);
  const [isGiftList, setIsGiftList] = useState(false);
  const [orderLoaded, setOrderLoaded] = useState(false);
  const [dynamicUpdated, setDynamicUpdated] = useState(false);
  const [giftEmail, setGiftEmail] = useState(null);
  const [shippingMethod] = useState('');
  const [giftListInfo, setGiftListInfo] = useState('');
  const [occProfileData, setOccProfileData] = useState('');
  const [secondMemberName, setSecondMemberName] = useState('');
  const [firstMemberName, setFirstMemberName] = useState('');
  const [cartPayload, setCartPayload] = useState('');
  const [giftUrl, setGiftURL] = useState('');
  const [adress, setAdress] = useState({});
  const [cartValidation, setCartValidation] = useState([]);
  const site = useSite();
  const baseURL = useBaseUrl();
  const urlString = window.location.href;
  const url = new URL(urlString);
  const currentProfileEmail = currentProfile.email;
  const giftUserEmail = url.searchParams.get('email');
  const profileBillingAddress = Object.keys(occProfileData).length > 0 ? occProfileData.billingAddress : '';
  const {commerceItems = {}} = currentOrder;

  const getGiftListData = useCallback(async listId => {
    const {
      delta: {localRepository}
    } = await action('getWeddingListPublic', {listId});

    const {
      weddingList: {
        undefined: {list}
      }
    } = localRepository || {};

    setFirstMemberName(list.first_member_name);
    setSecondMemberName(list.second_member_name);

    return list;
  }, []);

  const updateCart = () => {
    const adressInfo = giftListInfo.giftInfos;
    const adressNames = `${adressInfo.first_member_name} ${adressInfo.second_member_name}`;
    if (isGiftList === true) {
      action('updateCart', {
        items: cartPayload,
        c4_giftListAddress: JSON.stringify(adress),
        c4_firstMemberName: adressInfo.first_member_name,
        c4_giftListId: adressInfo.id.toString(),
        c4_isGiftList: true,
        c4_userLeftTheList: false,
        c4_giftListOwnersNames: adressNames,
        c4_giftListEmail: adressInfo.owner_email,
        c4_giftListName: adressInfo.name,
        c4_shippingMethod: 'correios'
      });
    }
  };

  const clearUserData = () => {
    if (isUserLoggedIn) {
      action('updateProfile', {
        firstName: occProfileData.firstName,
        lastName: occProfileData.lastName,
        email: occProfileData.email,
        c4_giftListInfos: null
      });
    } else {
      axios.post(`${baseURL}/ccstorex/custom/v1/updateProfileData`, {
        email: giftEmail
      });
    }
    setIsGiftList(false);
    setGiftListInfo('');
    setOccProfileData('');
  };

  useEffect(() => {
    const giftListProperty =
      currentOrder.dynamicProperties &&
      currentOrder.dynamicProperties.find(propertie => propertie.id === GIFT_LIST_DYNAMIC_PROPERTY);

    setIsGiftList(giftListProperty?.value);
  }, [currentOrder]);

  useEffect(() => {
    if (site !== null && !giftUrl) {
      setGiftURL(site.siteSettings.urlSettings.casamentoStoreMS);
      try {
        const currentUrl = window.location.href;
        if (currentUrl.includes('/lista-convidado?list')) {
          window.location.href = `${site.siteSettings.urlSettings.casamentoStoreMS}/lista-convidado${
            currentUrl.split('/lista-convidado')[1]
          }`;
        }
      } catch (error) {
        throw new Error(error);
      }
    }
  }, [giftUrl, site]);

  useEffect(() => {
    if (isUserLoggedIn === true && currentProfileEmail !== undefined && giftEmail !== null && isGiftList === true) {
      // eslint-disable-next-line no-undef
      setTimeout(() => {
        currentProfileEmail !== giftEmail ? action('logout') : console.log('User Validation Passed');
        setLoggedOff(true);
      }, 1000);
    }
  }, [action, currentProfileEmail, giftEmail, isUserLoggedIn]);

  useEffect(() => {
    if (giftUserEmail !== null) {
      try {
        axios.get(`${baseURL}/ccstorex/custom/v1/getProfileData?email=${giftUserEmail}`).then(response => {
          const data = response.data[0];
          const infos = data.c4_giftListInfos;
          if (infos !== null) {
            setOccProfileData(data);
            setGiftListInfo(JSON.parse(infos));
            setGiftEmail(giftUserEmail);
            if (Object.keys(infos).length > 0) {
              setIsGiftList(true);
            }
          }
        });
      } catch (err) {
        console.error('error on getProfileData', err);
      }
    }
  }, [baseURL, loggedOff]);

  useEffect(() => {
    if (isGetCartInProgress !== 1) {
      setOrderLoaded(true);
    }
  }, [isGetCartInProgress]);

  useEffect(() => {
    const itemsArray = giftListInfo.items;
    if (itemsArray !== undefined && productAdded === false) {
      setProductAdded(true);
      const adressInfo = giftListInfo.giftInfos;
      setFirstMemberName(adressInfo.first_member_name);
      setSecondMemberName(adressInfo.second_member_name);
      if (Object.keys(giftListInfo).length > 0) {
        const adressInfo = giftListInfo.giftInfos;
        const memberFullName = adressInfo.first_member_name.split(/(\s+)/);
        const giftShippingAddress = {
          firstName: memberFullName[0],
          lastName: memberFullName[memberFullName.length - 1],
          address1: adressInfo.street,
          address2: adressInfo.number,
          address3: adressInfo.complement,
          city: adressInfo.city,
          state: adressInfo.state,
          c4_neighborhood: adressInfo.district,
          phoneNumber: adressInfo.first_member_phone,
          country: 'BR',
          selectedCountry: 'BR',
          postalCode: adressInfo.zip_code,
          email: giftEmail
        };
        setAdress(giftShippingAddress);
      }

      if (commerceItems !== undefined && Object.keys(commerceItems).length > 0) {
        const commerceArray = Object.values(commerceItems);

        for (let i = 0; i < commerceItems.length; i++) {
          const productSKU = commerceArray[i].catRefId;
          const occSku = giftListInfo.items[i].id;
          productSKU === occSku
            ? setCartValidation(cartValidation.push(true))
            : setCartValidation(cartValidation.push(true));
        }

        const addItems = cartValidation.every(element => {
          return element === true;
        });

        return addItems;
      }

      const payload = itemsArray.map(product => {
        const item = {
          catRefId: product.id,
          quantity: product.quantity,
          productId: product.productId
        };

        return item;
      });

      action('addItemsToCart', {items: payload}).then(() => {
        setCartPayload(payload);
      });
    }
  }, [orderLoaded, giftListInfo]);

  useEffect(() => {
    if (Object.keys(commerceItems).length > 0 && dynamicUpdated === false) {
      setDynamicUpdated(true);
    }
  }, [cartPayload]);

  useEffect(() => {
    if (shippingMethod !== '') {
      const shippingGroup = Object.values(currentOrder.shippingGroups)[0];
      updateCartShippingMethod(action, shippingMethod, shippingGroup.shippingGroupId).then(shippingResp =>
        console.log('Shipping updated', shippingResp)
      );
    }
  }, [shippingMethod]);

  return (
    <GiftListContext.Provider
      value={{
        adress,
        profileBillingAddress,
        isGiftList,
        giftUrl,
        giftEmail,
        giftListInfo,
        firstMemberName,
        secondMemberName,
        updateCart,
        clearUserData,
        getGiftListData
      }}
    >
      {children}
    </GiftListContext.Provider>
  );
};

export function useGiftList() {
  return useContext(GiftListContext);
}
