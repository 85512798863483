import {useSelector} from '@oracle-cx-commerce/react-components/provider';
import React, {useState, createContext, useContext, useEffect, useCallback} from 'react';
import {getCategoryForCurrentPage} from '@oracle-cx-commerce/commerce-utils/selector';
import {useWLProductSelectionContext} from './jbq-wl-product-selection';
import {PAGE_LINKS_WITH_PRODUCT_SELECTION, WL_PARENT_CATEGORY_ID} from '../constants';

export const WLProductRoute = createContext([]);

export const WLProductRouteProvider = props => {
  const categoryForCurrentPage = useSelector(getCategoryForCurrentPage);
  const parentCategory = categoryForCurrentPage?.fixedParentCategories
    ? categoryForCurrentPage.fixedParentCategories[0]
    : false;

  const [hasWLProductRoute, setHasWLProductRoute] = useState({
    isSomeWLProductActive: false,
    route: ''
  });
  const {productSelection, setProductSelection, urlMustHaveSelectionContext} = useWLProductSelectionContext();
  const [isWeddingList, setIsWeddingList] = useState(false);
  const [breadcrumbInfos, setBreadcrumbInfo] = useState('');
  const [isWLBreadcrumb, setIsWLBreadcrumb] = useState(false);

  const handleVerifyIsWLBreadcrumb = useCallback(breadcrumbInfos => {
    const verifyWLCategory = breadcrumbInfos.map(category => {
      if (category.ancestors.length !== 0) return category.ancestors[0].label === 'Sugestão de Listas';

      return false;
    });

    if (verifyWLCategory[0]) return setIsWLBreadcrumb(true);

    setIsWLBreadcrumb(false);
  }, []);

  useEffect(() => {
    if (!breadcrumbInfos.length) return;

    handleVerifyIsWLBreadcrumb(breadcrumbInfos);
  }, [breadcrumbInfos, handleVerifyIsWLBreadcrumb]);

  useEffect(() => {
    const isWeddingListConst = parentCategory && parentCategory.id === WL_PARENT_CATEGORY_ID;
    setIsWeddingList(isWeddingListConst || isWLBreadcrumb);
  }, [parentCategory, setIsWeddingList, categoryForCurrentPage, isWeddingList, isWLBreadcrumb]);

  useEffect(() => {
    if (!isWeddingList || !parentCategory.childCategories) return;

    parentCategory.childCategories.forEach(category => {
      PAGE_LINKS_WITH_PRODUCT_SELECTION.push(category.repositoryId);
    });
  }, [isWeddingList, parentCategory]);

  useEffect(() => {
    if (hasWLProductRoute.route === '') return;

    (function () {
      const {pushState} = history;
      const {replaceState} = history;

      history.pushState = function () {
        pushState.apply(history, arguments);
        window.dispatchEvent(new Event('pushstate'));
        window.dispatchEvent(new Event('locationchange'));
      };

      history.replaceState = function () {
        replaceState.apply(history, arguments);
        window.dispatchEvent(new Event('replacestate'));
        window.dispatchEvent(new Event('locationchange'));
      };

      window.addEventListener('popstate', function () {
        window.dispatchEvent(new Event('locationchange'));
      });
    })();

    window.addEventListener('locationchange', function (e) {
      const getCurrentUrl = e?.path ? e?.path[0]?.location?.pathname : e?.target?.location?.pathname;

      urlMustHaveSelectionContext(getCurrentUrl, isWeddingList);
    });
  }, [urlMustHaveSelectionContext, hasWLProductRoute, isWeddingList, productSelection, setProductSelection]);

  return (
    <WLProductRoute.Provider
      value={{hasWLProductRoute, setHasWLProductRoute, isWeddingList, categoryForCurrentPage, setBreadcrumbInfo}}
    >
      {props.children}
    </WLProductRoute.Provider>
  );
};

export const useWLProductRouteContext = () => useContext(WLProductRoute);
