export const JBQWLBannerSpotlightContainer = () => import('./jbq-wl-banner-spotlight-container');
export const JBQWLBannerSpotlightList = () => import('./jbq-wl-banner-spotlight-list');
export const JBQWLBannerSpotlightCard = () => import('./jbq-wl-banner-spotlight-card');
export const JBQWLSuggestedLists = () => import('./jbq-wl-suggested-lists');
export const JBQWLSuggestedItem = () => import('./jbq-wl-suggested-item');
export const JBQWLFeaturedBanner = () => import('./jbq-wl-featured-banner');
export const JBQWLProfileNavigation = () => import('./jbq-wl-profile-navigation');
export const JBQWLCreateList = () => import('./jbq-wl-create-list');
export const JBQWLSearchLists = () => import('./jbq-wl-search-lists');
export const JBQWLMyLists = () => import('./jbq-wl-my-lists');
export const JBQWLListDetails = () => import('./jbq-wl-list-details');
export const JBQWLImageList = () => import('./jbq-wl-image-list');
export const JBQWLExtract = () => import('./jbq-wl-extract');
export const JBQWLSelectedGiftList = () => import('./jbq-wl-selected-gift-list');
export const JBQWLEarnedProductsListing = () => import('./jbq-wl-earned-products-listing');
export const JBQWLBuyGiftList = () => import('./jbq-wl-buy-gift-list');
