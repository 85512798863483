/* eslint-disable no-undef */

import {createStore} from '@oracle-cx-commerce/store';
import {createWapi} from '@oracle-cx-commerce/wapi';
import {loadVisitorService} from '@oracle-cx-commerce/react-app/utils';
import {preloadComponents} from '@oracle-cx-commerce/commerce-utils/react';
import {getPage, getGlobalContext} from '@oracle-cx-commerce/commerce-utils/selector';
import {render} from './render';

export const createClient = async ({endpoints, actions, components, middleware, subscribers = {}}) => {
  const {state} = window;
  console.assert(
    state,
    'Missing browser global window.state (i.e. the intial application state)--this should get set in the "index.html"'
  );
  const wapi = createWapi({endpoints});
  const store = createStore({actions, state, wapi, middleware});
  const {endpoint} = wapi;
  const {getState, subscribeDispatch} = store;
  Object.values(subscribers).forEach(async subscriber => {
    (await subscriber()).default({endpoint, getState, subscribeDispatch});
  });
  await preloadComponents(components, state);
  if (getPage(state).isPreview || !getGlobalContext(state).production) {
    window.store = store;
    window.wapi = wapi;
  }
  let appIsHandlingError = false;
  addEventListener('error', async error => {
    if (!appIsHandlingError) {
      appIsHandlingError = true;
      await endpoint('logClientErrors', error, store.getState());
      appIsHandlingError = false;
      console.warn(`Error "${error.message}" has been logged with the OCC server`);
    }
  });
  const start = async () => {
    console.time('Hydrate');
    store.action('init');
    store.action('initRecommendations', {url: location});
    render({components, store, container: document.querySelector('#root')});
    await store.action('getCloudConfiguration');
    loadVisitorService(store);
    console.timeEnd('Hydrate');
  };

  return {
    ...store,
    ...wapi,
    start
  };
};
